import { computed, ref, watch } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'

export default function useItemList() {
  // Use toast
  const toast = useToast()

  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {
      key: 'codigo_proposta',
      label: 'Proposta',
      width: '20%',
    },
    {
      key: 'nome',
      label: 'nome',
      class: 'font-size-8',
    },
    {
      key: 'disciplinas',
      label: 'disciplinas',
    },
    {
      key: 'tipo_custo',
      label: 'Subdisciplinas',
    },
    {
      key: 'valor',
      label: 'Valor',
      class: 'text-center',
    },
    {
      key: 'data_entrega',
      label: 'Entrega',
      class: 'text-center',
    },
    {
      key: 'status',
      label: 'Status',
      class: 'd-flex justify-content-center',
    },
    {
      key: 'Ações',
      class: 'text-center'
    },
  ]
  const perPage = ref(10)
  const totalItens = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    // eslint-disable-next-line no-unused-expressions
    currentPage.value - 1

    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalItens.value,
    }
  })

  const refetchData = () => {
    refUserListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], (newVal, oldVal) => {
    refetchData()
  })

  const fetchDados = (ctx, callback) => {
    store
      .dispatch('app-propostas/fetchDados', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,

      })
      .then(response => {
        callback(response.data)
        totalItens.value = response.data.totalItens
      })

  }

  const deleteItem = id => {
    store.dispatch('app-propostas/deleteItem', id)
      .then(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Proposta excluída com sucesso',
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
        refetchData()
      })
  }
  const create = item => {
    store.dispatch('app-propostas/store', item)
      .then(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Proposta criada com sucesso 2',
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
        router.push({ name: 'propostas' })
      })
  }
  const update = item => {
    store.dispatch('app-propostas/update', item)
      .then(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Proposta editada com sucesso ',
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
        router.push({ name: 'propostas' })
      })
  }

  const alterarStatus = item => {
    store.dispatch('app-propostas/alterarStatus', item)
      .then(() => {
        window.history.back()

        toast({
          component: ToastificationContent,
          props: {
            title: 'Status alterado com sucesso ',
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
      })
  }

  const getItem = id => {
    return store.dispatch('app-propostas/show', id)
  }

  return {
    fetchDados,
    deleteItem,
    tableColumns,
    perPage,
    currentPage,
    totalItens,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,
    refetchData,
    update,
    alterarStatus,
    create,
    getItem
  }
}
